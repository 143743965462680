import React from "react"
import classes from "./title.module.css"
const Title = ({ title }) => {
  return (
    <div>
      <h1 className={classes.subtitle}>{title}</h1>
    </div>
  )
}
export default Title
