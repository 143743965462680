import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/normalize.css"
import "../utils/css/screen.css"
import Title from "../components/title/subtitle"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const Works = ({ data }) => {
  useEffect(() => {
    gsap.fromTo(".page-head", { y: 20, opacity: 0 }, { y: 0, opacity: 1 })
    gsap.fromTo(
      ".post-content-body",
      { y: 20, opacity: 0 },
      { y: 0, opacity: 1, delay: 0.5 }
    )
  }, [])

  const post = data.contentfulWorks

  return (
    <Layout>
      <SEO
        title="制作実績"
        description="しょうのまきの制作実績はこちらから。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。"
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />

      <header className="page-head">
        <Title title="制作実績" />
      </header>

      <article className="post-content post-content-width">
        <div className="post-content-body works">
          <div
            dangerouslySetInnerHTML={{
              __html: post.body ? post.body.childContentfulRichText.html : null,
            }}
          />
        </div>
      </article>
    </Layout>
  )
}
export default Works

export const pageQuery = graphql`
  {
    contentfulWorks {
      body {
        childContentfulRichText {
          html
        }
      }
    }
  }
`
